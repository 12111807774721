/* styles.css */
.pulsate , .overlay{
    animation: pulsate 1.5s ease-in-out infinite;
  }
  
  @keyframes pulsate {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.03);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .relative {
    position: relative;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10%;
    right: -10%;
    height: 100%;
    width: 80%;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .overlay .text {
    color: white;
    font-size: 24px;
    padding-bottom: 5vh;
    font-weight: bold;
  }
  
  .relative .overlay {
    opacity: .25;
  }

  .overlay{
    display: flex;
    justify-content: center;
    align-items: end;
  }
  