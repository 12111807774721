.previewImgParent {
    margin-top:10px;
    width:100%;
    position: relative;
    padding-top:68.59%;
    overflow:hidden;
}

.previewImg {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    object-fit:cover;
}

.modal-dark .modal-content {
    background: rgba(0, 0, 0, 0.95);
    border: none;
    border-radius: 8px;
    backdrop-filter: blur(10px);
}

.image-editor-container {
    position: relative;
    width: 100%;
    background: transparent;
    padding: 20px 20px 80px 20px;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
}

.image-editor-title {
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 20px;
    font-size: 1.2rem;
    font-weight: 400;
    text-align: center;
}

.aspect-ratio-selector {
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
    justify-content: center;
}

.aspect-ratio-button {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: white;
    padding: 6px 12px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: all 0.2s ease;
}

.aspect-ratio-button:hover {
    background: rgba(255, 255, 255, 0.2);
}

.aspect-ratio-button.active {
    background: rgba(255, 255, 255, 0.25);
    border-color: rgba(255, 255, 255, 0.5);
}

.image-container {
    position: relative;
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 20px;
    min-height: 400px;
}

.ReactCrop {
    background: transparent !important;
    max-height: 100%;
    max-width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ReactCrop > img {
    max-width: 95% !important;
    max-height: 95% !important;
    object-fit: contain;
}

.ReactCrop__crop-selection {
    border: 2px solid rgba(255, 255, 255, 0.9) !important;
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.75) !important;
    border-radius: 0;
    cursor: move !important;
    touch-action: none;
}

.ReactCrop__drag-handle {
    width: 12px;
    height: 12px;
    background-color: white;
    border-radius: 50%;
    opacity: 0.9;
    border: 1px solid rgba(0, 0, 0, 0.3);
    touch-action: none;
    cursor: grab !important;
}

.ReactCrop__drag-handle:active {
    cursor: grabbing !important;
}

.ReactCrop__drag-handle:hover {
    transform: scale(1.1);
}

.ReactCrop__drag-bar {
    cursor: move !important;
}

.ReactCrop__drag-bar.ord-n,
.ReactCrop__drag-bar.ord-s {
    height: 20px;
    margin-top: -10px;
}

.ReactCrop__drag-bar.ord-e,
.ReactCrop__drag-bar.ord-w {
    width: 20px;
    margin-left: -10px;
}

.ReactCrop__drag-handle.ord-nw,
.ReactCrop__drag-handle.ord-ne,
.ReactCrop__drag-handle.ord-sw,
.ReactCrop__drag-handle.ord-se {
    width: 14px;
    height: 14px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.3);
    opacity: 1;
    cursor: nwse-resize !important;
}

.ReactCrop__child-wrapper {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    min-height: 300px;
    width: 100%;
    touch-action: none;
}

.ReactCrop__child-wrapper img {
    transform-origin: center center;
    max-width: 90% !important;
    margin: auto;
    touch-action: none;
}

@media (max-width: 768px) {
    .ReactCrop__child-wrapper img {
        max-height: 90% !important;
    }
}

@media (min-width: 769px) {
    .ReactCrop__child-wrapper img {
        max-height: 60vh !important;
    }
}

.image-container img {
    max-width: 100%;
    max-height: calc(80vh - 200px);
    object-fit: contain;
}

.rotate-button {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(255, 255, 255, 0.15);
    border: none;
    color: white;
    padding: 8px 16px;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: all 0.2s ease;
    z-index: 1000;
    font-size: 0.9rem;
}

.rotate-button:hover {
    background: rgba(255, 255, 255, 0.25);
}

.rotate-icon {
    width: 16px;
    height: 16px;
    opacity: 0.9;
}

.footer-buttons {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    z-index: 1001;
    backdrop-filter: blur(8px);
}

.btn-footer {
    padding: 8px 20px;
    font-size: 0.9rem;
    border-radius: 6px;
    transition: all 0.2s ease;
    border: none;
    cursor: pointer;
}

.btn-footer.btn-cancel {
    background: rgba(255, 255, 255, 0.15);
    color: white;
}

.btn-footer.btn-cancel:hover {
    background: rgba(255, 255, 255, 0.25);
}

.btn-footer.btn-save {
    background: #2563eb;
    color: white;
}

.btn-footer.btn-save:hover {
    background: #1d4ed8;
}

/* Eliminar bordes y handles innecesarios */
.ReactCrop__drag-handle::after,
.ReactCrop__drag-handle::before {
    display: none;
}

.ReactCrop__crop-selection::before {
    display: none;
}

/* Regla de tercios */
.ReactCrop__rule-of-thirds-vt::before,
.ReactCrop__rule-of-thirds-vt::after,
.ReactCrop__rule-of-thirds-hz::before,
.ReactCrop__rule-of-thirds-hz::after {
    background-color: rgba(255, 255, 255, 0.2) !important;
}

/* Mejorar la visualización durante el arrastre */
.ReactCrop--dragging {
    cursor: grabbing !important;
}

.ReactCrop--dragging .ReactCrop__drag-handle {
    cursor: grabbing !important;
}