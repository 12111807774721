/* Provide sufficient contrast against white background */
@tailwind base;
@tailwind components;
@tailwind utilities;

.snowflake {
  position: fixed;
  z-index: 50;
  pointer-events: none;
  color: #ffd90094;
  animation: starfall 15s linear infinite;
}

@keyframes starfall {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(200vh);
  }
}

.index-full {
  z-index: 9999 !important;
}


h1 {
  text-align: center;
}

.swal-confirm-btn {
  background-color: #4CAF50; /* Verde */
  color: white;
}

.swal-confirm-btn:hover {
  background-color: #45a049;
}

.swal-cancel-btn {
  background-color: #f44336; /* Rojo */
  color: white;
}

.swal-cancel-btn:hover {
  background-color: #e53935;
}

code {
  color: #E01A76;
}
.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.container {
  min-width: 100% !important;
  padding: 0 !important;
}

.rolImg {
  display: flex !important;
  justify-content: center !important;
}

.letra-cursiva {
  font-family: "Tangerine", cursive;
  font-style: normal;
  font-weight: 400;
}

.letra-text{
  font-family: "Ibarra Real Nova", serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.bg-img-fixed {
  background-repeat: no-repeat;
  background-position: center center;
  /* background-attachment: fixed !important; */
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

Label {
  color: #0366d6;
  font-size: x-large;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.title-form {
  color: #032a56;
  font-size: xx-large;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  margin: 10px;
}

.modal-co {
  display: flex !important;
  position: fixed !important;
  justify-content: center !important;
  align-items: center !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  overflow: auto !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 0px;
  border: 1px solid #888;
  width: 30%;
}

.modal-body{
  padding: 0 !important;
}
@media (max-width: 568px) {
  .modal-content {
    width: 90%;
  }
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.txt-orizontal {
  writing-mode: vertical-lr;
  text-orientation: upright;
}

.txt-pre {
  font-family: 'Times New Roman', Times, serif;
}

.sobrePon {
  width: 100px;
  height: 100px;
  position: absolute;
  background: #b7b7b7;
}

.azul {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.rojo {
  color: white;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #646464;
  z-index: 2;
}

.cursive-cloria-efrain {
  font-family: "Pacifico", cursive;
  font-weight: 400;
  font-style: normal;
}

:root {
  --primary: #fff;
  --bg-color: rgb(5, 53, 61);
  --bg-envelope-color: #f5edd1;
  --envelope-tab: #ecdeb8;
  --envelope-cover: #e6cfa7;
  --shadow-color: rgba(0, 0, 0, 0.2);
  --txt-color: #444;
  --heart-color: #f5edd1;
}

.container {
  height: 100vh;
  display: grid;
  place-items: center;
}

.container>.envelope-wrapper {
  background: var(--bg-envelope-color);
  box-shadow: 0 0 40px var(--shadow-color);
}

.envelope-wrapper>.envelope {
  position: relative;
  width: 300px;
  height: 230px;
}

.envelope-wrapper>.envelope::before {
  content: "";
  position: absolute;
  top: 0;
  z-index: 2;
  border-top: 130px solid var(--envelope-tab);
  border-right: 150px solid transparent;
  border-left: 150px solid transparent;
  transform-origin: top;
  transition: all 0.5s ease-in-out 0.7s;
}

.envelope-wrapper>.envelope::after {
  content: "";
  position: absolute;
  z-index: 2;
  width: 0px;
  height: 0px;
  border-top: 130px solid transparent;
  border-right: 150px solid var(--envelope-cover);
  border-bottom: 100px solid var(--envelope-cover);
  border-left: 150px solid var(--envelope-cover);
}

.envelope>.letter {
  position: absolute;
  right: 20%;
  bottom: 0;
  width: 54%;
  height: 80%;
  text-align: center;
  transition: all 1s ease-in-out;
  box-shadow: 0 0 5px var(--shadow-color);
  padding: 20px 10px;
  background-size: cover;
  background-position: center;
}

.envelope>.letter>.text {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: var(--txt-color);
  text-align: left;
  font-size: 10px;
}

.heart {
  position: absolute;
  top: 45%;
  left: 50%;
  width: 15px;
  height: 15px;
  background: #E01A76;
  z-index: 4;
  transform: translate(-50%, -20%) rotate(45deg);
  transition: transform 0.5s ease-in-out 1s;
  box-shadow: 0 1px 6px var(--shadow-color);
  cursor: pointer;
}

.heart:before,
.heart:after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: #E01A76;
  border-radius: 50%;
}

.heart:before {
  top: -7.5px;
}

.heart:after {
  right: 7.5px;
}

.heart2 {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 15px;
  height: 15px;
  background: #E01A76;
  z-index: 4;
  transform: translate(-50%, -20%) rotate(45deg);
  transition: transform 0.5s ease-in-out 1s;
  box-shadow: 0 1px 6px var(--shadow-color);
  cursor: pointer;
}

.heart2:before,
.heart2:after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: #E01A76;
  border-radius: 50%;
}

.heart2:before {
  top: -7.5px;
}

.heart2:after {
  right: 7.5px;
}

.flap>.envelope:before {
  transform: rotateX(180deg);
  z-index: 0;
}

.flap>.envelope>.letter {
  bottom: 100px;
  transform: scale(1.5);
  transition-delay: 1s;
}

.flap>.heart {
  transform: rotate(90deg);
  transition-delay: 0.4s;
}

.flap>.heart2 {
  transform: rotate(90deg);
  transition-delay: 0.4s;
}

.ver-invitacion-btn {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #d15d5d93;
  transition: background-color 0.3s;
  text-align: center;
}

.ver-invitacion-btn:hover {
  background-color: #d15d5d;
  color: #fff;
}

.texto-corazon {
  text-align: center;
  margin-top: 1px;
  padding: 30px;
  background: var(--envelope-cover);
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS';
}