.book-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: #2d2d2d;
  padding: 20px;
}

.book-container {
  width: 320px;
  height: 450px;
  position: relative;
  perspective: 1000px;
}

.pages {
  display: flex;
  width: 300%;
  height: 100%;
  transition: transform 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.page {
  flex: 1;
  min-width: 100%;
  border-radius: 30px;
  padding: 30px;
  box-shadow: 0 10px 30px rgba(0,0,0,0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
}

/* Página de texto */
.text-page {
  color: white;
  font-size: 1.2rem;
  line-height: 1.6;
  padding: 40px 25px;
}

.text-page p {
  margin: 15px 0;
}

/* Página de la pareja */
.couple-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.couple-page h1 {
  color: #ff6b6b;
  font-size: 2.5rem;
  margin-bottom: 40px;
}

.couple-icons {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 20px;
}

.icon-blue, .icon-pink {
  width: 60px;
  height: 120px;
  border-radius: 30px;
  position: relative;
}

.icon-blue {
  background: #4a90e2;
}

.icon-pink {
  background: #ff6b6b;
}

/* Página de nubes */
.clouds-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.clouds-page h1 {
  color: #ff6b6b;
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.clouds-page h2 {
  color: #ff6b6b;
  font-size: 1.8rem;
  margin-bottom: 30px;
}

.pink-clouds {
  width: 100%;
  height: 250px;
  background: linear-gradient(to bottom, #ffe4e4 50%, #ffb6b6 100%);
  border-radius: 25px;
  position: relative;
  overflow: hidden;
}

.stick-figures {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 40px;
  background: #333;
  border-radius: 20px 20px 0 0;
}

/* Navegación */
.navigation {
  margin-top: 30px;
  display: flex;
  gap: 20px;
}

.nav-button {
  width: 45px;
  height: 45px;
  border: none;
  border-radius: 50%;
  background: #ff6b6b;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.nav-button:hover:not(:disabled) {
  transform: scale(1.1);
  background: #ff8787;
}

.nav-button:disabled {
  background: #666;
  cursor: not-allowed;
  opacity: 0.5;
}

/* Efectos de sombra y bordes */
.page::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 30px;
  box-shadow: inset 0 0 20px rgba(0,0,0,0.2);
  pointer-events: none;
}

/* Animaciones */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.page > * {
  animation: fadeIn 0.6s ease-out forwards;
}
