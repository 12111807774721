.dropdown {
  display: inline-block;
  position: relative;
  width:100%;
}

.dropdown input {
    width:100%;
    border: 1px solid;
    height:40px;
}

.dropdown-content {
    display: none;
    position: absolute;
    width: 100%;
    max-height:150px;
    overflow-y:scroll;
    overflow-x:hidden;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index:100;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown-content .item {
    display: block;
    color: #000000;
    background-color:#d9d9d9;
    padding: 5px;
    text-decoration: none;
}

.dropdown-content .item.selected{
    background-color:#575f9f;
}

.dropdown-content .item:hover {
    color: #FFFFFF;
    background-color: #293ff3;
}